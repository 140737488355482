import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  createTransform,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ISystemStore } from 'types/stores';
import ConversationReducer from './modules/conversation';
import InstructionReducer from './modules/instruction';
import PromptReducer from './modules/prompt';
import SocketReducer from './modules/socket';
import SystemReducer from './modules/system';

const reducers = combineReducers({
  conversation: ConversationReducer,
  instruction: InstructionReducer,
  system: SystemReducer,
  prompt: PromptReducer,
  socket: SocketReducer,
});

const systemTransform = createTransform(
  (inboundState: ISystemStore, key) => {
    const updatedState = { ...inboundState };
    if (key === 'system') {
      delete updatedState.isUploading;
      delete updatedState.disabledSidebars;
    }
    return updatedState;
  },

  (outboundState) => {
    return outboundState;
  },

  { whitelist: ['system'] }
);

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    whitelist: ['system'],
    transforms: [systemTransform],
  },
  reducers
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootStateType = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatchType = typeof store.dispatch;
