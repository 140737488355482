import { ISelectOption } from 'components/atoms/SelectOptionsList';
import { IPresetObject } from 'types/layout';
import { ISourceOption, IUploadOption } from 'types/openai';

export const MATH_PROMPT =
  'Aways format math expressions by using $(inline math)$ or $$(math block)$$ instead of [(math)]';

export const DEFAULT_SYSTEM_PROMPT =
  "You are an AI assistant who helps people. Follow the user's instructions carefully.";

export const STREAMING_MESSAGE = 'Generating reply. Please wait.';

export const UPLOADING_FILE_LIMIT = 'You can upload up to 10 files or images per chat.';

export const UPLOADING_IMAGE_SIZE_LIMIT = 'You can upload up a maximum size of 20MB per chat';

export const UPLOADING_FILE_SIZE_LIMIT = 'You can upload up a maximum size of 40MB per chat';

export const CONVERSATION_STORAGE_KEY = 'selectedConversation';

export const BEES_ASSISTANT_TOOLTIP_STORAGE_KEY = 'beesAssistantTooltipShowed';

export const DEFAULT_TEMPERATURE = 0.5;

export const DEFAULT_SOURCE = 'GPT';

export const DEFAULT_CONTEXT_WINDOW = 10;

export const DEFAULT_MODEL = 'gpt-4o';

export const CODE_INTERPRETER = {
  PROGRESS: '\n ## Data Analysis in progress. \n',
  SUCCESS:
    '#### Execution complete. \nThe execution is complete. Please check the results. If there are any errors, the AI will continue to refine the code and attempt to resolve them.',
  FAILED:
    '#### Execution failed. \nThe initial attempt did not produce any results. The AI will continue to refine the code and try again.',
};

export const GPT_SOURCES: ISourceOption[] = [
  { id: DEFAULT_SOURCE, description: "GPT's knowledge base" },
  { id: 'Web', description: 'Web browsing' },
  { id: 'NFAStorybook', description: 'Storybook NFA' },
  { id: 'APFStorybook', description: 'Storybook BEES One' },
  { id: 'Confluence', description: 'Confluence' },
];

export const CONTEXT_OPTIONS: ISelectOption[] = new Array(26)
  .fill({})
  .map((_item, index) => ({ value: index, title: `${index} messages` }))
  .slice(2);

export const RESPONSE_TYPE_OPTIONS: ISelectOption[] = [
  {
    title: 'Creative',
    description: 'Responses are diverse and random.',
    value: 1,
  },
  {
    title: 'Neutral',
    description: 'Responses are balanced.',
    value: 0.5,
  },
  {
    title: 'Precise',
    description: 'Responses are accurate and straightforward.',
    value: 0,
  },
];

export enum ESegmentInfoTypes {
  TOKEN = 'Token',
  LEGAL_DISCLAIMER = 'Legal',
  LEARN_MORE = 'Learn More',
}

export enum ESegmentEntityTypes {
  CHAT = 'Chat',
  PROMPT = 'Prompt',
  ASSISTANT = 'Assistant',
  ASSISTANT_NPS = 'Assistant NPS',
  ANSWER = 'Answer',
  CHAT_INSTRUCTION = 'Chat Instruction',
}

export enum ESegmentOpenTypes {
  OPEN = 'Open',
  CLOSE = 'Close',
}

export enum ESegmentSidebarTypes {
  CHAT = 'Chat',
  PROMPT = 'Prompt',
}

export enum ESegmentEvaluationTypes {
  ANSWER = 'Answer',
  PRE_DEFINED_PROMPT = 'Pre Defined Prompt',
  PRE_DEFINED_CHAT_INSTRUCTION = 'Pre Defined Chat Instruction',
}

export enum ESegmentDragTypes {
  INSIDE = 'Inside',
  OUTSIDE = 'Outside',
  FOLDER_TO_FOLDER = 'Folder to Folder',
}

export enum ESegmentChatSettingsActionTypes {
  QUICK_SETTING = 'Quick Setting',
  CHAT_SETTINGS = 'Chat Settings',
}

export enum ESegmentPromptActionTypes {
  DISMISS = 'Dismiss',
  CHAT_SHORTCUT = 'Chat Shortcut',
  SIDE_BAR = 'Side Bar',
  SENT = 'Sent',
}

export enum ESegmentActionTypes {
  USED = 'Used',
  SENT = 'Sent',
  CLOSE = 'Close',
  COPIED = 'Copied',
  CLONED = 'Cloned',
  SUCCESS = 'Success',
  STARTED = 'Started',
  DISMISS = 'Dismiss',
  CLICKED = 'Clicked',
  SELECTED = 'Selected',
  MODIFIED = 'Modified',
  DISPLAYED = 'Displayed',
  DOWNLOADED = 'Downloaded',
}

export type SourceIdType = (typeof GPT_SOURCES)[number]['id'];

export const LOADER_MESSAGES = {
  Web: 'Searching the web for your answer. Wait a moment.',
  [DEFAULT_SOURCE]: 'Analyzing your file(s). Wait a moment.',
};

export const UPLOAD_OPTIONS: IUploadOption[] = [
  { id: 'image', description: 'Image' },
  { id: 'file', description: 'File' },
];

export const DEFAULT_CONVERSATION = {
  name: 'New Conversation',
  modelId: DEFAULT_MODEL,
  prompt: DEFAULT_SYSTEM_PROMPT,
  temperature: DEFAULT_TEMPERATURE,
  folderId: null,
  expired: false,
  contextWindow: 4,
};

export const COLLAPSED_SIDEBAR_SIZES: IPresetObject = {
  0: 'default',
  1: 'medium',
  2: 'large',
};

export const MODEL_DESCRIPTION = {
  'gpt-35-turbo': 'Fastest model for daily tasks',
  'gpt-4o': 'Newest and most advanced model',
  'gpt-4-turbo': 'Advanced model for complex tasks',
};

export const PROGRAMMING_LANGUAGES = {
  javascript: '.js',
  python: '.py',
  java: '.java',
  c: '.c',
  cpp: '.cpp',
  'c++': '.cpp',
  'c#': '.cs',
  ruby: '.rb',
  php: '.php',
  swift: '.swift',
  'objective-c': '.m',
  kotlin: '.kt',
  typescript: '.ts',
  go: '.go',
  perl: '.pl',
  rust: '.rs',
  scala: '.scala',
  haskell: '.hs',
  lua: '.lua',
  shell: '.sh',
  sql: '.sql',
  html: '.html',
  css: '.css',
};

export const FEEDBACK_TYPE_OPTIONS = {
  prompt: {
    NOT_RELEVANT: 'Not relevant',
    NOT_HELPFUL: 'Not helpful',
    OTHER: 'Other (specify)',
  },
  answer: {
    NOT_HELPFUL: 'Not helpful',
    NOT_CORRECT: 'Not correct',
    OTHER: 'Other (specify)',
  },
};

export const ALLOWED_FILE_TYPES = ['docx', 'xlsx', 'csv', 'pdf'];

export const ALLOWED_IMAGE_TYPES = ['jpeg', 'png', 'webp'];
